<template>
  <div class="fill-height">

    <template v-for="(category, j ) in rootCategories" >
      <div :key="'category' + j">
        <h3 v-if="rootCategoryCondition(category)" :class="[{'mb-5' : childCategories(category.value).length === 0}]"> {{ category.text }}</h3>

        <div v-if="!SM" class="fill-height">
          <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(category.value)" :key="'course' + i + j + k" max-height="240" class="wsRoundedHalf mb-6 d-flex">

            <div>
              <v-img transition="xxx" v-if="item.img" class="wsRoundedHalfLeft" height="240" width="274"  :src="item.img" ></v-img>
              <v-img transition="xxx" v-else class="wsRoundedHalfLeft" height="240" width="274"  src="@/assets/img/course_blank.jpg" ></v-img>
            </div>

            <div style="width: 100%;" class="pa-6">
              <h3 style="font-weight: 700">{{  item.name }}</h3>
              <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium mt-3">
                {{item.short_description }}
              </h5>
              <v-progress-linear
                  :value="item.completion"
                  :color="wsATTENTION"
                  :background-color="wsBACKGROUND"
                  height="10"
                  class="mt-5"
                  rounded
              />
              <h5  class="mt-3">{{ $t('Completion')  }} : {{ item.completion }} %</h5>
              <v-btn :to="businessDashLink('courses/' + item.uuid + '/student' )"
                     :color="wsATTENTION"
                     elevation="0"
                     dark class="noCaps mt-5"
              >
                {{ $t('ContinueCourse') }}
                <v-icon class="ml-3">mdi-chevron-right</v-icon>
              </v-btn>
            </div>

          </v-sheet>
        </div>
        <div v-if="SM" class="fill-height" >
          <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(category.value)" :key="'course' + i + j + k"  width="100%"  class="wsRoundedHalf mb-6">
            <div style="width: 100%">
              <v-img v-if="item.img" transition="xxx" class="wsRoundedHalfTop" height="200" width="auto"   :src="item.img"  />
              <v-img transition="xxx" v-else class="wsRoundedHalfTop" height="200" width="auto"  src="@/assets/img/course_blank.jpg" />
            </div>
            <div style="width: 100%;" class="pa-3">
              <h4 style="font-weight: 700">{{  item.name }}</h4>
              <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium ">
                {{item.short_description }}
              </h5>
              <v-progress-linear
                  :value="item.completion"
                  :color="wsATTENTION"
                  :background-color="wsBACKGROUND"
                  height="10"
                  class="mt-5"
                  rounded
              />
              <h5  class="mt-3">{{ $t('Completion')  }} : {{ item.completion }} %</h5>
              <v-btn :to="businessDashLink('courses/' + item.uuid + '/student' )"
                     :color="wsATTENTION"
                     elevation="0"
                     block
                     dark class="noCaps mt-5 mb-1"
              >
                {{ $t('ContinueCourse') }}
                <v-icon class="ml-3">mdi-chevron-right</v-icon>
              </v-btn>
            </div>



          </v-sheet>
        </div>

        <template v-for="(child,k) in childCategories(category.value)">
          <div :key="'child_category' + j + k">
            <h4 v-if="categoryCourses(child.value).length > 0"  class="mb-5"> {{ child.text }}</h4>
            <div v-if="!SM" class="fill-height">
              <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(child.value)" :key="'course' + i + j + k" max-height="240" class="wsRoundedHalf mb-6 d-flex">

                <div>
                  <v-img transition="xxx" v-if="item.img" class="wsRoundedHalfLeft" height="240" width="274"  :src="item.img" ></v-img>
                  <v-img transition="xxx" v-else class="wsRoundedHalfLeft" height="240" width="274"  src="@/assets/img/course_blank.jpg" ></v-img>
                </div>

                <div style="width: 100%;" class="pa-6">
                  <h3 style="font-weight: 700">{{  item.name }}</h3>
                  <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium mt-3">
                    {{item.short_description }}
                  </h5>
                  <v-progress-linear
                      :value="item.completion"
                      :color="wsATTENTION"
                      :background-color="wsBACKGROUND"
                      height="10"
                      class="mt-5"
                      rounded
                  />
                  <h5  class="mt-3">{{ $t('Completion')  }} : {{ item.completion }} %</h5>
                  <v-btn :to="businessDashLink('courses/' + item.uuid + '/student' )"
                         :color="wsATTENTION"
                         elevation="0"
                         dark class="noCaps mt-5"
                  >
                    {{ $t('ContinueCourse') }}
                    <v-icon class="ml-3">mdi-chevron-right</v-icon>
                  </v-btn>
                </div>

              </v-sheet>
            </div>
            <div v-if="SM" class="fill-height" >
              <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(child.value)" :key="'course' + i + j + k"  width="100%"  class="wsRoundedHalf mb-6">
                <div style="width: 100%">
                  <v-img transition="xxx" v-if="item.img" class="wsRoundedHalfTop" height="200" width="auto"   :src="item.img"  />
                  <v-img transition="xxx" v-else class="wsRoundedHalfTop" height="200" width="auto"  src="@/assets/img/course_blank.jpg" />
                </div>
                <div style="width: 100%;" class="pa-3">
                  <h4 style="font-weight: 700">{{  item.name }}</h4>
                  <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium ">
                    {{item.short_description }}
                  </h5>
                  <v-progress-linear
                      :value="item.completion"
                      :color="wsATTENTION"
                      :background-color="wsBACKGROUND"
                      height="10"
                      class="mt-5"
                      rounded
                  />
                  <h5  class="mt-3">{{ $t('Completion')  }} : {{ item.completion }} %</h5>
                  <v-btn :to="businessDashLink('courses/' + item.uuid + '/student' )"
                         :color="wsATTENTION"
                         elevation="0"
                         block
                         dark class="noCaps mt-5 mb-1"
                  >
                    {{ $t('ContinueCourse') }}
                    <v-icon class="ml-3">mdi-chevron-right</v-icon>
                  </v-btn>
                </div>



              </v-sheet>
            </div>
          </div>
        </template>



      </div>
    </template>






  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  data() {
    return {
      items : [],
      lastActivity : {},
      newCourseNavigation : 'myStudy',

      categories : [],
    }
  },
  computed : {
    ...mapState( 'business', [ 'selectedBusiness' ] ),

    rootCategories() {
      if ( this.categories.length === 0 ) {
        return [{text: '' , value: 'all'}]
      }
      let items = this.categories.filter( el => el.parent === null )
      items.push({text: this.$t('UnsortedCourses'), value: 'unsorted'} )
      return items
    },
    newCourseNavigationItems() {
      return [
        { text : this.$t('MyStudy') , value : 'myStudy' },
        { text : this.$t('AllCourses') , value : 'courses' },
      ]
    },



  },
  methods : {
    ...mapActions( 'dashboard', [ 'GET_DASHBOARD_USER_WESTUDY' ] ),
    ...mapActions('courses', ['GET_COURSES_STUDENT']),

    childCategories(parentId) {
      return this.categories.filter(el => el.parent === parentId )
    },
    categoryCourses(selectedCategory) {
      let items = this.items
      if (selectedCategory !== 'all') {
        let category = selectedCategory !== 'unsorted' ? selectedCategory : null
        items = items.filter( el => el.category_id === category )
      }
      return items
    },
    rootCategoryCondition(item) {
      if ( this.childCategories(item.value).length === 0 ) {
        if ( this.categoryCourses(item.value).length === 0 ) {
          return false
        }
      } else {
        let result = false
        this.childCategories(item.value).forEach(child => {
          if ( this.categoryCourses(child.value).length > 0 ) {
            result = true
          }
        })

        return result
      }
      return true
    }

  },
  async mounted() {

    let result = await this.GET_COURSES_STUDENT() || [] ;
    if ( !result ) {
      return
    }
    this.items = result.courses
    this.categories = result.categories

  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>